.clickable {
	cursor: pointer !important;
}

.clickable.fas,
a {
	color: $link;
}

.clickable.fas:hover,
a:hover {
	color: scale-color($link, $lightness: +20%);
}

.hover-link,
.table-link {
	border-radius: 3px;
	margin: -5px -8px;
	padding: 5px 8px;
}

.table-link {
	display: inline-block;
}

.table-link:hover,
.hover-link:hover {
	background-color: $np_cream;
	text-decoration: none;
}

.dim-link-container .dim-link {
	display: inline-block;
	cursor: pointer;
	padding: 0px 10px;
	opacity: 0.2;
}

.dim-link-container:hover {
	.dim-link {
		opacity: 0.8;
		color: $link;
	}
	.dim-link:hover {
		opacity: 1;
	}
}

.form-group {
	margin-bottom: 1rem;
}

.file-upload-overlay {
	position: relative;
	input {
		top: 0px;
		left: 0px;
		position: absolute;
		height: 100%;
		width: 100%;
		opacity: 0;
		z-index: 1000;
		cursor: pointer;
	}
}

.invalid-form-field-symbol {
	position: absolute;
	right: 10px;
	top: -3px;
	font-size: 150%;
}
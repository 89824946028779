body {
	background-color: $np_cream !important;
}

.auth-page-aside {
	background-color: $np_deepblue;
}

.mat-drawer {
	background-color: $np_black !important;

	.mdc-list-item,
	.mdc-list-item * {
		color: $np_cream !important;
	}

	.mdc-list-item:hover,
	.mdc-list-item.active {
		background-color: $np_cream !important;
		* {
			color: $np_black !important;
		}
	}

	.footer {
		color: $np_cream !important;
	}
}

div.main-menu-toggle {
	background-color: $np_black !important;
	button {
		background-color: inherit;
		color: $np_cream !important;
	}
	button:hover {
		background-color: $np_cream !important;
		color: $np_black !important;
	}
}

.mat-drawer-content {
	background-color: $np_cream !important;
}

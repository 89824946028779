/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/ol/ol.css";

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px !important;
	width: 100% !important;
}

.position-static {
	position: static;
}

$np_brown: rgb(84, 67, 47);
$np_deepblue: rgb(90, 98, 105);
$np_deepblue_darker: scale-color($np_deepblue, $lightness: -20%);
$np_deepblue_lighter: scale-color($np_deepblue, $lightness: +20%);
$np_blue: rgb(175, 189, 192);
$np_cream: rgb(244, 241, 234);
$np_black: black;
$np_white: white;

$link: scale-color($np_blue, $lightness: -40%);

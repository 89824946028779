body {
  background-color: rgb(244, 241, 234) !important;
}

.auth-page-aside {
  background-color: rgb(90, 98, 105);
}

.mat-drawer {
  background-color: black !important;
}
.mat-drawer .mdc-list-item,
.mat-drawer .mdc-list-item * {
  color: rgb(244, 241, 234) !important;
}
.mat-drawer .mdc-list-item:hover,
.mat-drawer .mdc-list-item.active {
  background-color: rgb(244, 241, 234) !important;
}
.mat-drawer .mdc-list-item:hover *,
.mat-drawer .mdc-list-item.active * {
  color: black !important;
}
.mat-drawer .footer {
  color: rgb(244, 241, 234) !important;
}

div.main-menu-toggle {
  background-color: black !important;
}
div.main-menu-toggle button {
  background-color: inherit;
  color: rgb(244, 241, 234) !important;
}
div.main-menu-toggle button:hover {
  background-color: rgb(244, 241, 234) !important;
  color: black !important;
}

.mat-drawer-content {
  background-color: rgb(244, 241, 234) !important;
}

.badge {
  padding: 7px;
}

.badge-primary {
  background-color: rgb(90, 98, 105);
}

.btn:disabled {
  cursor: not-allowed;
}

.btn-primary {
  background-color: rgb(90, 98, 105);
  border-color: rgb(90, 98, 105);
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #484e54;
  border-color: #484e54;
}

.btn-primary:disabled {
  background-color: #77828b;
  border-color: #77828b;
}

.btn-secondary {
  background-color: rgb(244, 241, 234);
  border-color: rgb(90, 98, 105);
  color: rgb(90, 98, 105);
}

.button-group {
  display: flex;
  gap: 10px;
}
.button-group button {
  flex-grow: 1;
}

mat-card {
  background-color: white;
  border: solid 1px rgb(175, 189, 192);
}

mat-card.card-block {
  margin-bottom: 20px;
}

mat-card.full-height {
  height: calc(100% - 20px);
  margin-bottom: 20px;
}

mat-card-header:last-child mat-card-title:last-child,
mat-card-header:last-child mat-card-subtitle:last-child {
  margin-bottom: 0px !important;
}

.mat-card-header-text:first-child {
  margin-left: 0px;
}

.card-header-toolbar {
  flex-grow: 1;
}
.card-header-toolbar ul.card-header-nav {
  display: inline-block;
  margin: 0px;
  margin-top: -5px;
  padding: 0px;
  padding-bottom: 10px;
  font-size: 150%;
}
.card-header-toolbar ul.card-header-nav li {
  display: inline-block;
  margin: 0px 10px;
}
.card-header-toolbar ul.card-header-nav li a {
  padding: 5px;
  border-radius: 5px;
}
.card-header-toolbar ul.card-header-nav li a.active {
  background-color: #61777b;
  color: white;
}
.card-header-toolbar ul.dropdown-filters {
  font-size: 12px;
  margin-bottom: 10px;
}
.card-header-toolbar ul.dropdown-filters div {
  text-align: center;
}

.card-header-toolbar.right {
  text-align: right;
}

.form-group {
  margin-bottom: 1rem;
}

.file-upload-overlay {
  position: relative;
}
.file-upload-overlay input {
  top: 0px;
  left: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1000;
  cursor: pointer;
}

.invalid-form-field-symbol {
  position: absolute;
  right: 10px;
  top: -3px;
  font-size: 150%;
}

.clickable {
  cursor: pointer !important;
}

.clickable.fas,
a {
  color: #61777b;
}

.clickable.fas:hover,
a:hover {
  color: #7d9499;
}

.hover-link,
.table-link {
  border-radius: 3px;
  margin: -5px -8px;
  padding: 5px 8px;
}

.table-link {
  display: inline-block;
}

.table-link:hover,
.hover-link:hover {
  background-color: rgb(244, 241, 234);
  text-decoration: none;
}

.dim-link-container .dim-link {
  display: inline-block;
  cursor: pointer;
  padding: 0px 10px;
  opacity: 0.2;
}

.dim-link-container:hover .dim-link {
  opacity: 0.8;
  color: #61777b;
}
.dim-link-container:hover .dim-link:hover {
  opacity: 1;
}

td .badge {
  white-space: normal;
}

td.user-link {
  text-align: center;
  vertical-align: middle;
}

html,
body,
login-page {
  height: 100%;
}

.page-padding {
  padding: 10px;
}

@font-face {
  font-family: "CentraLight";
  src: url("../../fonts/CentraNo1-Light.ttf");
}
@font-face {
  font-family: "CentraBook";
  src: url("../../fonts/CentraNo1-Book.ttf");
}
@font-face {
  font-family: "CentraMedium";
  src: url("../../fonts/CentraNo1-Medium.ttf");
}
* {
  font-family: "CentraLight" !important;
}

.mat-sidenav * {
  font-family: "CentraBook" !important;
}

* {
  font-size: 18px;
}

mdc-list-item {
  font-family: "CentraBook";
}

.fas {
  font-family: "Font Awesome 5 Free" !important;
}
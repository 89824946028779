mat-card {
	background-color: $np_white;
	border: solid 1px $np_blue;
}

mat-card.card-block {
	margin-bottom: 20px;
}

mat-card.full-height {
	height: calc(100% - 20px);
	margin-bottom: 20px;
}

mat-card-header:last-child {
	mat-card-title:last-child,
	mat-card-subtitle:last-child {
		margin-bottom: 0px !important;
	}
}

.mat-card-header-text:first-child {
	margin-left: 0px;
}

.card-header-toolbar {
	flex-grow: 1;
	ul.card-header-nav {
		display: inline-block;
		margin: 0px;
		margin-top: -5px;
		padding: 0px;
		padding-bottom: 10px;
		font-size: 150%;
		li {
			display: inline-block;
			margin: 0px 10px;
			a {
				padding: 5px;
				border-radius: 5px;
			}
			a.active {
				background-color: $link;
				color: white;
			}
		}
	}
	ul.dropdown-filters {
		font-size: 12px;
		div {
			text-align: center;
		}
		margin-bottom: 10px;
	}
}

.card-header-toolbar.right {
	text-align: right;
}

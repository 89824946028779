@font-face {
	font-family: "CentraLight";
	src: url("../../fonts/CentraNo1-Light.ttf");
}
@font-face {
	font-family: "CentraBook";
	src: url("../../fonts/CentraNo1-Book.ttf");
}
@font-face {
	font-family: "CentraMedium";
	src: url("../../fonts/CentraNo1-Medium.ttf");
}

* {
	font-family: "CentraLight" !important;
}

.mat-sidenav * {
	font-family: "CentraBook" !important;
}

* {
	font-size: 18px;
}

mdc-list-item {
	font-family: "CentraBook";
}

.fas {
	font-family: "Font Awesome 5 Free" !important;
}

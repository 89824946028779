.btn:disabled {
	cursor: not-allowed;
}

.btn-primary {
	background-color: $np_deepblue;
	border-color: $np_deepblue;
}

.btn-primary:hover,
.btn-primary:active {
	background-color: $np_deepblue_darker;
	border-color: $np_deepblue_darker;
}

.btn-primary:disabled {
	background-color: $np_deepblue_lighter;
	border-color: $np_deepblue_lighter;
}

.btn-secondary {
	background-color: $np_cream;
	border-color: $np_deepblue;
	color: $np_deepblue;
}

.button-group {
	display: flex;
	gap: 10px;
	button {
		flex-grow: 1;
	}
}
